<template>
  <!-- modal -->
  <div class="modal" v-bind:class="{open: shopping}" v-if="shopping">
    <div class="modal_box">
      <div class="header">
        <h4>ご注文内容の詳細</h4>
        <div class="close modal_close" @click="$emit('canceled')"></div>
      </div>
      <div class="inner cart">
        <div class="scroll_wrap">
          <div class="scroll_box">
            <div class="item_detail flex">
              <pre class="shopping-pre">{{ shopping.text }}</pre>
            </div>
            <div class="btn_wrap">
              <a href="javascript:void(0)" @click="$emit('canceled')" class="submit light skeleton">閉じる</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ovr"></div>
  </div>
  <!-- modal -->
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  props: {
    shopping_id: null
  },
  data() {
    return {
      shopping: null
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.axios
          .get(`${this.env.api_base_url}shop/shopping_text.json`, {
            params: {id: this.shopping_id}
          })
          .then(response => {
            this.shopping = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
            this.$emit('canceled')
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }

}
</script>
