<template>
  <div id="container" v-if="fetch_data">

    <section id="mypage">
      <div class="wrap">

        <!-- オンラインショップ購入履歴 -->
        <div class="cts_box">
          <h2 class="sub_ttl"><span>オンラインショップ購入履歴</span></h2>
          <p class="lead_txt">
            「オンラインショップ」でご注文いただいた商品の一覧です。
          </p>

          <template v-if="fetch_data.not_shipped.length">
            <h3 class="min_ttl">現在未発送の商品</h3>
            <p class="lead_txt">
              ご注文いただいている商品のうち、現在未発送のものを表示しています。
            </p>
            <div class="online_shop_box undispatched">
              <div class="order_group" v-for="ship in fetch_data.not_shipped" v-bind:key="ship.id" v-bind:class="{cancel: ship.status === 'status_cancel'}">
                <ul class="online_shop_list">
                  <li v-for="(article, index) in ship.articles" v-bind:key="ship.id + '-' + article.id">
                    <div class="buy_data flex">
                      <table>
                        <tbody>
                        <tr class="head">
                          <th class="date">ご注文日</th>
                          <th class="price">金額</th>
                          <th class="task"></th>
                          <th class="address">お届け先</th>
                        </tr>
                        <tr>
                          <td class="date">{{ moment(ship.updated_at, 'YYYY年MM月DD日') }}</td>
                          <td class="price">¥ {{ delimited(article.price) }}</td>
                          <td class="task" v-if="ship.status === 'status_payment_waiting' || ship.status === 'status_bank_waiting'"><span
                                  class="unpaid">代金支払待</span></td>
                          <td class="task" v-if="ship.status === 'status_shipping' || ship.status === 'status_new_order'"><span
                                  class="paid">商品発送待ち</span>
                          </td>
                          <td class="task" v-if="ship.status === 'status_cancel'"><span
                                  class="error">キャンセル</span>
                          </td>
                          <td class="task" v-if="ship.status === 'status_payment_failure'"><span
                                  class="error">決済失敗</span></td>
                          <td class="address">{{ `${ship.last_name} ${ship.first_name}` }} 様</td>
                        </tr>
                        </tbody>
                      </table>
                      <div class="btn pc" v-if="index === 0">
                        <a href="javascript:void(0)" @click="onShoppingDetailClicked(ship)" class="linkBtn">注文内容詳細</a>
                      </div>
                      <div class="btn pc" v-if="index === 0 && ship.user_receipt_id===123456">
                        <a href="javascript:void(0)" @click="receiptDownload(ship)" class="linkBtn">領収書ダウンロード</a>
                      </div>
                    </div>
                    <div class="detail flex">
                      <div class="icon">
                        <router-link :to="{ name: 'ShoppingArticle', params: {id: article.id} }">
                          <img v-bind:src="article.image_files[0]" alt="">
                        </router-link>
                      </div>
                      <div class="contents">
                        <router-link :to="{ name: 'ShoppingArticle', params: {id: article.id} }">
                          <h3>{{ article.title }}</h3>
                        </router-link>
                      </div>
                      <div class="btn sp" v-if="index === 0">
                        <a href="javascript:void(0)" @click="onShoppingDetailClicked(ship)" class="linkBtn">注文内容詳細</a>
                      </div>
                      <div class="btn sp" v-if="index === 0 && ship.user_receipt_id===123456">
                        <a href="javascript:void(0)" @click="receiptDownload(ship)" class="linkBtn">領収書ダウンロード</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>

          <template v-if="fetch_data.shipped.length">
            <h3 class="min_ttl">発送済みの商品</h3>
            <p class="lead_txt">
              それぞれの商品のお届けに関するお問合せにはお答えできません。お届け時間の変更や商品のお届けに関するご相談は、それぞれの配送会社へお願いいたします。
            </p>
            <div class="online_shop_box">
              <div class="order_group" v-for="ship in fetch_data.shipped" v-bind:key="ship.id">
                <ul class="online_shop_list">
                  <li v-for="(article, index) in ship.articles" v-bind:key="ship.id + '-' + article.id">
                    <div class="buy_data flex">
                      <table>
                        <tbody>
                        <tr class="head">
                          <th class="date">ご注文日</th>
                          <th class="price">金額</th>
                          <th class="address">お届け先</th>
                        </tr>
                        <tr>
                          <td class="date">{{ moment(ship.created_at, 'YYYY年MM月DD日') }}</td>
                          <td class="price">¥ {{ delimited(article.price) }}</td>
                          <td class="task" v-if="ship.status === 'status_payment_failure'"><span
                                  class="error">決済失敗</span></td>
                          <td class="address">{{ `${ship.last_name} ${ship.first_name}` }} 様</td>
                        </tr>
                        </tbody>
                      </table>
                      <div class="btn pc" v-if="index === 0">
                        <a href="javascript:void(0)" @click="onShoppingDetailClicked(ship)" class="linkBtn">注文内容詳細</a>
                      </div>
                      <div class="btn pc" v-if="index === 0 && ship.user_receipt_id===123456">
                        <a href="javascript:void(0)" @click="receiptDownload(ship)" class="linkBtn">領収書ダウンロード</a>
                      </div>
                    </div>
                    <div class="detail flex">
                      <div class="icon">
                        <router-link :to="{ name: 'ShoppingArticle', params: {id: article.id} }">
                          <img v-bind:src="article.image_files[0]" alt="">
                        </router-link>
                      </div>
                      <div class="contents">
                        <p>{{ article.title }}</p>
                      </div>
                      <div class="btn sp" v-if="index === 0">
                        <a href="javascript:void(0)" @click="onShoppingDetailClicked(ship)" class="linkBtn">注文内容詳細</a>
                      </div>
                      <div class="btn sp" v-if="index === 0 && ship.user_receipt_id===123456">
                        <a href="javascript:void(0)" @click="receiptDownload(ship)" class="linkBtn">領収書ダウンロード</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>

          <div class="button_wrap">
            <router-link :to="{ name: 'UsersCustomerAccount'}" class="submit arrow back light skeleton large">
              マイページに戻る
            </router-link>
          </div>
        </div>

        <ShoppingModal :shopping_id="shopping_id" v-if="shopping_id" v-on:canceled="shopping_id=null"/>

      </div>
    </section>

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import ShoppingModal from '@/views/shop/_shopping_modal.vue'

export default {
  mixins: [Libraries],
  components: {
    ShoppingModal,
  },
  data() {
    return {
      fetch_data: null,
      shopping_id: null
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}shop/histories.json`, {
            params: {
              page: this.$route.query.page
            }
          })
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    onShoppingDetailClicked(shopping) {
      this.shopping_id = shopping.id
    },
    receiptDownload(shopping) {
      location.href = `${this.env.api_base_url}users/receipt_download.pdf?id=${shopping.user_receipt_id}&token=${this.current_user.api_token}`
    },
  }
}
</script>
